define('satellite/test-helpers/mirage-jsonapi-helpers', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.indexHandler = indexHandler;
  exports.showHandler = showHandler;
  exports.createHandler = createHandler;
  exports.createHandlerWithFactory = createHandlerWithFactory;
  exports.updateHandler = updateHandler;
  exports.deleteHandler = deleteHandler;


  var helpers = {
    serializeRecord: function serializeRecord(record) {
      var fields = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

      return {
        data: this._serialize(record, fields)
      };
    },
    serializeCollection: function serializeCollection(records) {
      var _this = this;

      var fields = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

      return {
        data: records.map(function (record) {
          return _this._serialize(record, fields);
        })
      };
    },
    errorFor: function errorFor(attribute, message) {
      return {
        title: message,
        detail: attribute + ' ' + message,
        source: {
          pointer: '/data/attributes/' + attribute
        }
      };
    },
    extractAttributes: function extractAttributes(request) {
      return JSON.parse(request.requestBody).data.attributes || {};
    },
    extractRelationships: function extractRelationships(request) {
      var relationships = JSON.parse(request.requestBody).data.relationships || {};
      var data = {};

      Object.keys(relationships).forEach(function (key) {
        if (relationships[key].data) {
          data[key] = relationships[key].data.id || relationships[key].data.map(function (item) {
            return item.id;
          });
        } else {
          data[key] = null;
        }
      });

      return data;
    },
    _serialize: function _serialize(record, fields) {
      var _this2 = this;

      var attributes = {};
      var relationships = {};

      if (fields.length > 0) {
        fields.forEach(function (field) {
          attributes[field] = record[field];
        });
      } else {
        attributes = Ember.copy(record, true);
      }

      delete attributes.id;
      delete attributes._type;
      delete attributes._relationshipsMeta;

      if (record._relationshipsMeta) {
        Object.keys(record._relationshipsMeta).forEach(function (relationName) {
          if (record[relationName]) {
            delete attributes[relationName];
            relationships[relationName] = _this2._serializeRelation(record, relationName);
          }
        });
      }

      var response = {
        id: record.id,
        type: record._type || record.type,
        attributes: attributes
      };

      if (Object.keys(relationships).length > 0) {
        response.relationships = relationships;
      }

      return response;
    },
    _serializeRelation: function _serializeRelation(record, relationName) {
      var oneOrMany = record._relationshipsMeta[relationName].oneOrMany;
      var type = record._relationshipsMeta[relationName].type;
      var link = record._relationshipsMeta[relationName].link;
      var idOrIds = record[relationName];

      if (link) {
        return this._serializeLink(link, record);
      } else if (oneOrMany === 'one') {
        return this._serializeHasOne(relationName, type, idOrIds);
      } else {
        return this._serializeHasMany(relationName, type, idOrIds);
      }
    },
    _serializeLink: function _serializeLink(link, record) {
      return {
        links: { related: link.replace('{id}', record.id) }
      };
    },
    _serializeHasOne: function _serializeHasOne(key, type, idOrModel) {
      return {
        data: { type: type, id: this._extractId(idOrModel) }
      };
    },
    _serializeHasMany: function _serializeHasMany(key, type, idsOrModels) {
      var _this3 = this;

      var data = idsOrModels.map(function (idOrModel) {
        return { type: type, id: _this3._extractId(idOrModel) };
      });

      return { data: data };
    },
    _extractId: function _extractId(idOrModel) {
      if (idOrModel.id) {
        return idOrModel.id;
      } else {
        return idOrModel;
      }
    }
  };

  function indexHandler(collectionName, filterFunction) {
    return function (db, request) {
      var collection = db[collectionName];
      var meta = {};

      if (filterFunction) {
        var filters = {};

        for (var key in request.queryParams) {
          var matches = key.match(/^filter\[([a-z0-9\-_]+)\]/);

          if (matches) {
            filters[matches[1]] = request.queryParams[key];
          }
        }

        collection = filterFunction(collection, filters);
      }

      if (request.queryParams.sort) {
        var attrs = request.queryParams.sort.split(',');

        attrs.forEach(function (attr) {
          var func = gt;

          if (attr.indexOf('-') === 0) {
            func = lt;
            attr = attr.substring(1);
          }

          collection = collection.sort(function (a, b) {
            return func(a[attr], b[attr]);
          });
        });
      }

      meta = { total: collection.length };

      if (request.queryParams['page[number]']) {
        var page = parseInt(request.queryParams['page[number]'], 10) - 1;
        var size = request.queryParams['page[size]'] ? parseInt(request.queryParams['page[size]'], 10) : 10;

        collection = collection.slice(page * size, page * size + size);
      }

      var json = helpers.serializeCollection(collection, fieldsFor(collectionName, request));
      json.meta = meta;

      return json;
    };
  }

  function showHandler(collectionName) {
    return function (db, request) {
      var record = db[collectionName].find(request.params.id);

      if (record) {
        return helpers.serializeRecord(record, fieldsFor(collectionName, request));
      } else {
        return new _emberCliMirage.default.Response(404);
      }
    };
  }

  function createHandler(collectionName) {
    return function (db, request) {
      var attributes = helpers.extractAttributes(request);
      var relationships = helpers.extractRelationships(request);

      attributes = Ember.assign(attributes, relationships);

      attributes.type = collectionName;
      var record = db[collectionName].insert(attributes);

      return helpers.serializeRecord(record);
    };
  }

  function createHandlerWithFactory(mirage, factoryName) {
    return function (db, request) {
      var attributes = Ember.assign(helpers.extractAttributes(request), helpers.extractRelationships(request));

      var record = mirage.create(factoryName, attributes);

      return helpers.serializeRecord(record);
    };
  }

  function updateHandler(collectionName) {
    return function (db, request) {
      var attributes = Ember.assign(helpers.extractAttributes(request), helpers.extractRelationships(request));
      var record = db[collectionName].update(request.params.id, attributes);

      return helpers.serializeRecord(record);
    };
  }

  function deleteHandler(collectionName) {
    return function (db, request) {
      db[collectionName].remove(request.params.id);
      return { data: null };
    };
  }

  exports.default = helpers;


  function gt(left, right) {
    return left > right ? 1 : -1;
  }

  function lt(left, right) {
    return left < right ? 1 : -1;
  }

  function fieldsFor(collectionName, request) {
    var key = 'fields[' + collectionName + ']';

    if (request.queryParams[key]) {
      return request.queryParams[key].split(',');
    } else {
      return [];
    }
  }
});