define('shuttle/services/shuttle-contact', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),

    createRecord: function createRecord(attrs) {
      return this.get('store').createRecord('shuttle-contact', attrs).save();
    },
    updateRecord: function updateRecord(id, attrs) {
      var record = this._peekRecord(id);
      record.setProperties(attrs);

      return record.get('hasDirtyAttributes') ? record.save() : Ember.RSVP.resolve(record);
    },
    destroyRecord: function destroyRecord(id) {
      return this._peekRecord(id).destroyRecord();
    },
    saveAll: function saveAll(contacts, parent) {
      var _this = this;

      contacts = contacts.map(function (contact) {
        return _this._save(contact, parent);
      });
      return Ember.RSVP.all(contacts);
    },
    _peekRecord: function _peekRecord(id) {
      return this.get('store').peekRecord('shuttle-contact', id);
    },
    _save: function _save(_ref, parent) {
      var id = _ref.id,
          attrs = _ref.attrs,
          isNew = _ref.isNew,
          isDeleted = _ref.isDeleted;

      if (isNew) {
        return this.createRecord(Ember.assign(attrs, parent));
      } else if (isDeleted) {
        return this.destroyRecord(id);
      } else {
        return this.updateRecord(id, attrs);
      }
    }
  });
});