define('satellite/components/satellite-radio-buttons', ['exports', 'satellite/mixins/base-input', 'satellite/templates/components/satellite-radio-buttons'], function (exports, _baseInput, _satelliteRadioButtons) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_baseInput.default, {
    layout: _satelliteRadioButtons.default,

    checked: Ember.computed.alias('value'),
    isActive: true,

    actions: {
      change: function change(value) {
        this.set('checked', value);
      }
    }
  });
});