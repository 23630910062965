define('shuttle/components/shuttle-person', ['exports', 'shuttle/templates/components/shuttle-person'], function (exports, _shuttlePerson) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _shuttlePerson.default,

    store: Ember.inject.service('store'),
    personService: Ember.inject.service('shuttle-person'),
    widget: Ember.inject.service('shuttle-widget'),

    // Actions
    oninstitutionclick: function oninstitutionclick() {
      return undefined;
    },

    classNameBindings: [':shuttle-preview', ':shuttle-person', ':flex-column', 'hasFocused:shuttle-preview-has-focused'],

    hasFocused: Ember.computed('currentFocus', 'newMembershipForm', 'newRelationForm', function () {
      return this.get('currentFocus') || this.get('newMembershipForm') || this.get('newRelationForm');
    }),

    isAccordionDisabled: Ember.computed('currentFocus', 'hasFocused', function () {
      return this.get('hasFocused') && this.get('currentFocus') !== 'membership';
    }),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('personService').findRecord(this.get('uuid')).then(function (person) {
        _this.set('person', person);
      });

      this.set('newMembership', Ember.Object.create({ contacts: [] }));
    },


    actions: {
      savePerson: function savePerson(toggleForm, attrs) {
        var person = this.get('person');
        return this.get('personService').updateRecord(person, attrs).then(toggleForm);
      },
      archivePerson: function archivePerson() {
        var _this2 = this;

        var person = this.get('person');

        return this.get('personService').updateRecord(person, { isArchived: true }).then(function () {
          _this2.get('widget').temp('people');
        });
      },
      saveContacts: function saveContacts(data) {
        var person = this.get('person');
        return this.get('personService').updateContacts(person, data);
      },
      createMembership: function createMembership(data) {
        var person = this.get('person');
        return this.get('personService').createMembership(person, data);
      },
      updateMembership: function updateMembership(membership, data) {
        return this.get('personService').updateMembership(membership, data);
      },
      deleteMembership: function deleteMembership(membership) {
        var _this3 = this;

        return this.get('personService').deleteMembership(membership).then(function () {
          _this3.set('currentFocus', null);
        });
      },
      createRelation: function createRelation(attrs) {
        var person = this.get('person');
        return this.get('personService').createRelation(person, attrs);
      },
      deleteRelation: function deleteRelation(relation) {
        return this.get('personService').deleteRelation(relation);
      },
      setAttrAndUpdateScroll: function setAttrAndUpdateScroll(attrName, value) {
        this.set(attrName, value);
        this.$('.shuttle-scrollbar').trigger('update-scroll');
      },
      handleMembershipLinkClick: function handleMembershipLinkClick(membership) {
        var institutionId = membership.belongsTo('institution').id();
        return this.get('oninstitutionclick')(institutionId);
      }
    }
  });
});