define('shuttle/components/shuttle-preview/edit-mode', ['exports', 'shuttle/templates/components/shuttle-preview/edit-mode'], function (exports, _editMode) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _editMode.default,

    actions: {
      setEditMode: function setEditMode(value) {
        this.set('editMode', value);

        if (this.get('editMode') && this.get('onedit')) {
          this.get('onedit')();
        } else if (!this.get('editMode') && this.get('onpreview')) {
          this.get('onpreview')();
        }
      }
    }
  });
});