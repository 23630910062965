define('satellite/components/satellite-loader', ['exports', 'satellite/templates/components/satellite-loader'], function (exports, _satelliteLoader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _satelliteLoader.default,

    tagName: 'svg',
    classNames: ['satellite-loader'],
    attributeBindings: ['width', 'height', 'viewBox'],

    size: 24,
    stroke: 8,

    width: Ember.computed.reads('size'),
    height: Ember.computed.reads('size'),

    viewBox: Ember.computed('stroke', function () {
      var box = 60 + this.get('stroke');

      return '0 0 ' + box + ' ' + box;
    }),

    coordinate: Ember.computed('stroke', function () {
      return 30 + this.get('stroke') / 2;
    })

  });
});