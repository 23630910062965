define('repo/components/repo-recent-files', ['exports', 'repo/templates/components/repo-recent-files'], function (exports, _repoRecentFiles) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _repoRecentFiles.default,

    store: Ember.inject.service(),

    classNames: ['repo-recent-files'],
    title: 'Recently Uploaded Files',

    files: Ember.computed('app', function () {
      return this.get('store').query('repo-file', this._queryParams());
    }),

    _queryParams: function _queryParams() {
      return {
        sort: '-created-at',
        filter: { app: this.get('app') },
        page: { number: 1, size: 6 }
      };
    }
  });
});