define('satellite/components/satellite-modal', ['exports', 'satellite/utils/test-root-element-id', 'satellite/templates/components/satellite-modal'], function (exports, _testRootElementId, _satelliteModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _satelliteModal.default,

    closeOnBackdropClick: true,
    parentElement: null,
    position: 'right',
    renderInPlace: false,
    size: '540px',

    _parentElement: Ember.computed('parentElement', 'renderInPlace', function () {
      if (this.renderInPlace) {
        return this.element;
      } else {
        return this._getParentElement();
      }
    }),

    modalSize: Ember.computed('size', function () {
      return Ember.String.htmlSafe('width: ' + Ember.get(this, 'size') + ';');
    }),

    init: function init() {
      this._super.apply(this, arguments);

      var position = this.get('position');

      (false && !(Ember.isEmpty(position) || /^right|left|center$/.test(position)) && Ember.assert('The position of the modal can be set only to right, left or center', Ember.isEmpty(position) || /^right|left|center$/.test(position)));
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super();

      Ember.$('body').addClass('has-satellite-modal');

      Ember.$('#satellite-modal-backdrop').on('click.close-modal', function (e) {
        if (_this.get('closeOnBackdropClick') && e.target.id === 'satellite-modal-backdrop') {
          _this.send('close');
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$('body').removeClass('has-satellite-modal');
      Ember.$('#satellite-modal-backdrop').off('click.close-modal');

      this._super();
    },


    actions: {
      close: function close() {
        if (this._preventClose()) {
          return;
        }

        this.get('close')();
      }
    },

    _getParentElement: function _getParentElement() {
      var config = Ember.getOwner(this).resolveRegistration('config:environment');
      var id = this.parentElement || (0, _testRootElementId.default)(config) || 'satellite-modal-wormhole';

      return document.getElementById(id);
    },
    _preventClose: function _preventClose() {
      var beforeClose = this.get('beforeclose');
      return (beforeClose ? beforeClose() : true) === false;
    }
  });
});