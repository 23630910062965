define('satellite/components/satellite-dropdown/separator', ['exports', 'satellite/templates/components/satellite-dropdown/separator'], function (exports, _separator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _separator.default,

    classNames: ['satellite-dropdown-separator']
  });
});