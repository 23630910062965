define('repo/services/repo-widget', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var bindAction = function bindAction(method) {
    return Ember.computed(function () {
      return Ember.run.bind(this, this[method]);
    });
  };

  exports.default = Ember.Service.extend({
    router: Ember.inject.service(),
    currentRouteName: Ember.computed.readOnly('router.currentRouteName'),

    init: function init() {
      this._super.apply(this, arguments);
      this._reset();
      this.resetDefaults();
    },


    currentState: Ember.computed('tempState', 'states.lastObject', function () {
      return this.get('tempState') || this.get('states.lastObject');
    }),

    previousState: Ember.computed('states.[]', function () {
      var states = this.get('states');
      var length = states.get('length');

      return length > 1 ? states[length - 2] : null;
    }),

    // Helper props that can be passed to the action helper
    // it maybe a better idea to create helpers?
    openAction: bindAction('open'),
    tempAction: bindAction('temp'),
    alterAction: bindAction('alter'),
    backAction: bindAction('back'),
    closeAction: bindAction('close'),

    open: function open(component) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var nextState = this._createNextState(options, { component: component });
      this._pushState(nextState);
    },
    temp: function temp(component) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var tempState = this._createNextState(options, { component: component });
      this.set('tempState', tempState);
    },
    alter: function alter(options) {
      var nextState = this._createNextState(this.get('currentState'), options);
      this._pushState(nextState);
    },
    back: function back() {
      this._popState();
    },
    close: function close() {
      this._reset();
    },
    openScopedToCurrentRoute: function openScopedToCurrentRoute() {
      this.temp('files', { tags: this._getRouteTags() });
    },
    setDefaults: function setDefaults(defaults) {
      this._defaults = defaults || {};
    },
    getDefaults: function getDefaults() {
      return this._defaults;
    },
    resetDefaults: function resetDefaults() {
      this._defaults = {};
    },
    _pushState: function _pushState(state) {
      this.set('tempState', null);
      this.get('states').pushObject(state);
    },
    _popState: function _popState() {
      this.set('tempState', null);

      if (this.get('states.length') > 1) {
        this.get('states').popObject();
      }
    },
    _reset: function _reset() {
      this.setProperties({
        states: Ember.A([]),
        tempState: null
      });
    },
    _createNextState: function _createNextState() {
      for (var _len = arguments.length, objects = Array(_len), _key = 0; _key < _len; _key++) {
        objects[_key] = arguments[_key];
      }

      return Ember.assign.apply(undefined, [{}, this._defaults].concat(_toConsumableArray(objects)));
    },
    _getRouteTags: function _getRouteTags() {
      var container = Ember.getOwner(this);
      var segments = this.get('currentRouteName') ? this.get('currentRouteName').split('.') : [];

      return segments.map(function (name, index) {
        var path = segments.slice(0, index + 1).join('.');
        var route = container.lookup('route:' + path);

        return Ember.getWithDefault(route, 'repoTag', null);
      }).filter(function (tag) {
        return tag !== null;
      });
    }
  });
});