define('auth/mixins/adapter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    session: Ember.inject.service('session'),

    headers: Ember.computed(function () {
      return this.get('session').requestHeaders();
    }).volatile(),

    handleResponse: function handleResponse(status) {
      if (status === 401) {
        this.get('session').invalidate();
        return true;
      } else {
        return this._super.apply(this, arguments);
      }
    }
  });
});