define('shuttle/services/shuttle-institution', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    contactService: Ember.inject.service('shuttle-contact'),
    dialog: Ember.inject.service('satellite-dialog'),
    notify: Ember.inject.service('satellite-notify'),
    store: Ember.inject.service(),

    findRecord: function findRecord(id) {
      var include = 'tags,contacts,headquarters';
      return this.get('store').findRecord('shuttle-institution', id, { include: include });
    },
    updateRecord: function updateRecord(institution, attrs) {
      var _this = this;

      institution.setProperties(attrs);

      return institution.save().then(function () {
        _this.get('notify').success('Information was saved successfully.');
      });
    },
    updateContacts: function updateContacts(institution, contacts) {
      var _this2 = this;

      return this.get('contactService').saveAll(contacts, { institution: institution }).then(function () {
        _this2.get('notify').success('Contacts were saved successfully.');
      });
    },
    createHeadquarter: function createHeadquarter(institution, data) {
      var headquarter = this.get('store').createRecord('shuttle-headquarter', { institution: institution });
      return this._saveHeadquarter(headquarter, data);
    },
    updateHeadquarter: function updateHeadquarter(headquarter, data) {
      return this._saveHeadquarter(headquarter, data);
    },
    deleteHeadquarter: function deleteHeadquarter(headquarter) {
      var _this3 = this;

      return this.get('dialog').confirmDelete().then(function () {
        return headquarter.destroyRecord();
      }).then(function () {
        _this3.get('notify').success('Headquarter was deleted successfully.');
      });
    },
    _saveHeadquarter: function _saveHeadquarter(headquarter, _ref) {
      var _this4 = this;

      var attrs = _ref.attrs,
          contacts = _ref.contacts;

      headquarter.setProperties(attrs);

      return headquarter.save().then(function (headquarter) {
        return _this4.get('contactService').saveAll(contacts, { headquarter: headquarter });
      }).then(function () {
        _this4.get('notify').success('Headquarter was saved successfully.');
      });
    }
  });
});