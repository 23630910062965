define('satellite/components/satellite-dialog', ['exports', 'satellite/templates/components/satellite-dialog'], function (exports, _satelliteDialog) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _satelliteDialog.default,

    dialog: Ember.inject.service('satellite-dialog'),
    message: Ember.computed.readOnly('dialog.message'),

    hasMessage: Ember.computed('message', {
      get: function get() {
        var hasMessage = !Ember.isEmpty(this.get('message'));
        var callback = hasMessage ? this._attachDocumentEventHandler : this._detachDocumentEventHandler;
        Ember.run.scheduleOnce('afterRender', this, callback);

        return hasMessage;
      }
    }),

    displayCancelButton: Ember.computed('message.cancelLabel', function () {
      return this.get('message.cancelLabel') !== false;
    }),

    willDestroyElement: function willDestroyElement() {
      this._detachDocumentEventHandler();
    },


    actions: {
      confirm: function confirm() {
        this.get('dialog').onConfirm();
      },
      cancel: function cancel() {
        this.get('dialog').onCancel();
      }
    },

    _detachDocumentEventHandler: function _detachDocumentEventHandler() {
      this.$(document).off('keydown.dialog');
    },
    _attachDocumentEventHandler: function _attachDocumentEventHandler() {
      var _this = this;

      this.$(document).off('keydown.dialog').on('keydown.dialog', function (e) {
        e.preventDefault();

        if (e.keyCode === 27) {
          Ember.run(function () {
            return _this.send('cancel');
          });
        }

        if (e.keyCode === 13) {
          Ember.run(function () {
            return _this.send('confirm');
          });
        }
      });
    }
  });
});