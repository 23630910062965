define('shuttle/components/shuttle-person/membership-form', ['exports', 'shuttle/templates/components/shuttle-person/membership-form', 'shuttle/statics/contact-labels'], function (exports, _membershipForm, _contactLabels) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _membershipForm.default,

    init: function init() {
      var _get;

      this._super.apply(this, arguments);

      var contacts = this.get('membership.contacts').map(function (contact) {
        return Ember.Object.create({
          id: contact.get('id'),
          attrs: contact.getProperties('value', 'label', 'isPrimary')
        });
      });

      var fields = ['institution', 'headquarter', 'position', 'department', 'inOfficeSince', 'inOfficeUntil'];

      this.set('membership', Ember.Object.create({
        id: this.get('membership.id'),
        attrs: (_get = this.get('membership')).getProperties.apply(_get, fields),
        contacts: Ember.A(contacts)
      }));
    },


    actions: {
      save: function save() {
        this.set('isSaving', true);
        this.get('onsave')(this.get('membership')).then(this.get('oncancel'));
      },
      chooseInstitution: function chooseInstitution(institution) {
        this.set('membership.attrs.institution', institution);
        this.set('membership.attrs.headquarter', null);
      },
      addContact: function addContact() {
        var contact = Ember.Object.create({
          attrs: { label: _contactLabels.default[0].value },
          isNew: true
        });

        this.get('membership.contacts').addObject(contact);
      },
      handleContactDelete: function handleContactDelete(contact, value) {
        if (contact.get('isNew')) {
          this.get('membership.contacts').removeObject(contact);
        } else {
          contact.set('isDeleted', value);
        }
      }
    }
  });
});