define('repo/components/repo-list/search-selected', ['exports', 'repo/templates/components/repo-list/search-selected'], function (exports, _searchSelected) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _searchSelected.default,
    tagName: 'span',
    classNames: ['repo-list-search-selected']
  });
});