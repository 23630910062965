define('shuttle/models/shuttle-list', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    listType: (0, _attr.default)('string', { default: 'contact' }),
    name: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    conditions: (0, _attr.default)('string', { default: 'and' }),

    tags: (0, _relationships.hasMany)('shuttle-tag')
  });
});