define('satellite/components/satellite-pagination', ['exports', 'satellite/templates/components/satellite-pagination'], function (exports, _satellitePagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _satellitePagination.default,

    classNames: ['satellite-pagination'],

    prevButtonDisabled: Ember.computed('page', function () {
      return this.get('page') <= 1;
    }),

    nextButtonDisabled: Ember.computed('page', 'lastPage', function () {
      return this.get('page') >= this.get('lastPage');
    }),

    lastPage: Ember.computed('size', 'total', function () {
      var total = this.get('total') || 1;
      return Math.ceil(total / this.get('size'));
    }),

    fromRecord: Ember.computed('page', 'size', 'total', function () {
      return this.get('size') * (this.get('page') - 1) + 1;
    }),

    toRecord: Ember.computed('page', 'size', 'total', function () {
      var toRecord = this.get('size') * this.get('page');
      return toRecord > this.get('total') ? this.get('total') : toRecord;
    }),

    actions: {
      changeToPreviousPage: function changeToPreviousPage() {
        var previous = this.get('page') - 1;

        if (previous > 0) {
          this.get('onchange')(previous);
        }
      },
      changeToNextPage: function changeToNextPage() {
        var next = this.get('page') + 1;

        if (next <= this.get('lastPage')) {
          this.get('onchange')(next);
        }
      },
      handleInputChange: function handleInputChange(e) {
        var value = e.target.value;

        if (value > 0 && value <= this.get('lastPage')) {
          this.get('onchange')(value);
        }
      }
    }
  });
});