define('repo/components/repo-tag/form', ['exports', 'repo/templates/components/repo-tag/form'], function (exports, _form) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _form.default,

    // Actions
    onsave: function onsave() {},
    oncancel: function oncancel() {},

    init: function init() {
      this._super.apply(this, arguments);

      this.set('modelCopy', Ember.getProperties(this.model, 'name'));
    }
  });
});