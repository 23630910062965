define('shuttle/components/shuttle-preview/contacts-form', ['exports', 'shuttle/templates/components/shuttle-preview/contacts-form', 'shuttle/statics/contact-labels'], function (exports, _contactsForm, _contactLabels) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _contactsForm.default,

    init: function init() {
      this._super.apply(this, arguments);

      var contacts = this.get('contacts').map(function (contact) {
        return Ember.Object.create({
          id: contact.get('id'),
          attrs: contact.getProperties('isPrimary', 'value', 'label')
        });
      });

      this.set('contacts', Ember.A(contacts));
    },


    actions: {
      save: function save() {
        this.set('isSaving', true);
        this.get('onsave')(this.get('contacts')).then(this.get('oncancel'));
      },
      addContact: function addContact() {
        var contact = Ember.Object.create({
          attrs: { isPrimary: false, label: _contactLabels.default[0].value },
          isNew: true
        });

        this.get('contacts').addObject(contact);
      },
      handleDelete: function handleDelete(contact, value) {
        if (contact.get('isNew')) {
          this.get('contacts').removeObject(contact);
        } else {
          contact.set('isDeleted', value);
        }
      }
    }
  });
});