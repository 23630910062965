define('satellite/components/satellite-blanket', ['exports', 'satellite/utils/test-root-element-id', 'satellite/templates/components/satellite-blanket'], function (exports, _testRootElementId, _satelliteBlanket) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _satelliteBlanket.default,

    // Actions
    onclick: function onclick() {},

    parentElement: null,
    renderInPlace: false,

    _parentElement: Ember.computed('parentElement', 'renderInPlace', function () {
      if (this.renderInPlace) {
        return this.element;
      } else {
        return this._getParentElement();
      }
    }),

    didInsertElement: function didInsertElement() {
      this._super();
      document.body.classList.add('has-satellite-blanket');
    },
    willDestroyElement: function willDestroyElement() {
      document.body.classList.remove('has-satellite-blanket');
      this._super();
    },


    actions: {
      handleClick: function handleClick(e) {
        if (e.target.classList.contains('satellite-blanket')) {
          this.onclick();
        }
      }
    },

    _getParentElement: function _getParentElement() {
      var config = Ember.getOwner(this).resolveRegistration('config:environment');
      var id = this.parentElement || (0, _testRootElementId.default)(config) || 'satellite-blanket-wormhole';

      return document.getElementById(id);
    }
  });
});