define('shuttle/components/shuttle-button', ['exports', 'shuttle/templates/components/shuttle-button'], function (exports, _shuttleButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _shuttleButton.default,
    widget: Ember.inject.service('shuttle-widget')
  });
});