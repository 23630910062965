define('satellite/components/satellite-textarea', ['exports', 'satellite/mixins/base-input', 'satellite/templates/components/satellite-textarea'], function (exports, _baseInput, _satelliteTextarea) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_baseInput.default, {
    layout: _satelliteTextarea.default,

    rows: 3,
    lineHeight: 24,
    autoExpand: true,

    observeValue: Ember.observer('value', 'autoExpand', function () {
      this._resolveRows();
    }),

    didInsertElement: function didInsertElement() {
      this._super();

      this._textarea = this.$('textarea')[0];

      var savedValue = this._textarea.value;

      this._textarea.value = '';
      this._base = this._textarea.scrollHeight;
      this._textarea.value = savedValue;

      this._resolveRows();
      Ember.$(window).on('resize.textarea', Ember.run.bind(this, this._resolveRows));
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$(window).off('resize.textarea');
    },
    _resolveRows: function _resolveRows() {
      if (!this.get('autoExpand')) {
        return;
      }

      var minRows = parseInt(this.get('rows'), 10);
      var lineHeight = parseInt(this.get('lineHeight'), 10);

      this._textarea.rows = minRows;
      this._textarea.rows = minRows + Math.ceil((this._textarea.scrollHeight - this._base) / lineHeight);
    }
  });
});