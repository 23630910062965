define('ember-in-viewport/services/-raf-admin', ['exports', 'raf-pool'], function (exports, _rafPool4) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var RAFAdmin = function (_EmberService) {
    _inherits(RAFAdmin, _EmberService);

    function RAFAdmin() {
      _classCallCheck(this, RAFAdmin);

      return _possibleConstructorReturn(this, (RAFAdmin.__proto__ || Object.getPrototypeOf(RAFAdmin)).apply(this, arguments));
    }

    _createClass(RAFAdmin, [{
      key: 'init',
      value: function init() {
        this._super.apply(this, arguments);
        this._rafPool = new _rafPool4.default();
      }
    }, {
      key: 'add',
      value: function add() {
        var _rafPool;

        return (_rafPool = this._rafPool).add.apply(_rafPool, arguments);
      }
    }, {
      key: 'flush',
      value: function flush() {
        return this._rafPool.flush();
      }
    }, {
      key: 'remove',
      value: function remove() {
        var _rafPool2;

        return (_rafPool2 = this._rafPool).remove.apply(_rafPool2, arguments);
      }
    }, {
      key: 'reset',
      value: function reset() {
        var _rafPool3;

        (_rafPool3 = this._rafPool).reset.apply(_rafPool3, arguments);
        this._rafPool = null;
      }
    }]);

    return RAFAdmin;
  }(Ember.Service);

  exports.default = RAFAdmin;
});