define('satellite/components/satellite-evermore', ['exports', 'satellite/templates/components/satellite-evermore'], function (exports, _satelliteEvermore) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _satelliteEvermore.default,

    tagName: 'a',
    attributeBindings: ['href', 'target', 'rel'],
    classNames: ['satellite-evermore', 'flex-row', 'flex-center'],

    href: 'http://weareevermore.com/',
    target: '_blank',
    rel: 'nofollow'
  });
});