define('shuttle/components/shuttle-list/search-selected', ['exports', 'shuttle/templates/components/shuttle-list/search-selected'], function (exports, _searchSelected) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _searchSelected.default,
    tagName: 'span',
    classNames: ['shuttle-list-search-selected']
  });
});