define('shuttle/components/shuttle-person-loader', ['exports', 'shuttle/templates/components/shuttle-person-loader'], function (exports, _shuttlePersonLoader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ShuttlePersonLoader = Ember.Component.extend({
    layout: _shuttlePersonLoader.default,

    personService: Ember.inject.service('shuttle-person'),

    personPromise: Ember.computed('personId', function () {
      return this.get('personId') ? this.get('personService').findRecord(this.get('personId')) : null;
    })
  });

  ShuttlePersonLoader.reopenClass({
    positionalParams: ['personId']
  });

  exports.default = ShuttlePersonLoader;
});