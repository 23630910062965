define('shuttle/components/shuttle-slot/item', ['exports', 'shuttle/templates/components/shuttle-slot/item'], function (exports, _item) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _item.default,
    classNames: ['shuttle-slot-item', 'animate', 'flex-row', 'flex-center']
  });
});