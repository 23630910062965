define('satellite/transforms/datepicker', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    // Always parse the date in the current timezone
    deserialize: function deserialize(value) {
      if (value && value.indexOf('Z') > -1) {
        return new Date(value.replace('Z', ''));
      } else {
        return value;
      }
    },
    serialize: function serialize(value) {
      return value ? this._convertToUTC(value) : null;
    },
    _convertToUTC: function _convertToUTC(value) {
      return (0, _moment.default)(value).format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
    }
  });
});