define('satellite/components/satellite-dropdown/mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    classNames: ['satellite-dropdown-item', 'satellite-btn', 'satellite-btn-blank', 'satellite-btn-block'],

    init: function init() {
      this._super.apply(this, arguments);

      if (this.class && this.class.indexOf('-with-link') > -1) {
        Ember.deprecate('Use dropdown.link contextual component instead of -with-link class', false, {
          id: 'satellite.satellite-dropdown.deprecate-with-link',
          until: '0.14.0'
        });
        this.tagName = 'div';
      }
    },
    click: function click(e) {
      e.stopPropagation();

      if (this.get('onclick')) {
        this.get('onclick')();
      }
      if (this.get('closedropdown')) {
        this.get('closedropdown')();
      }

      return false;
    }
  });
});