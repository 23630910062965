define('satellite/components/satellite-dropdown/item', ['exports', 'satellite/templates/components/satellite-dropdown/item', 'satellite/components/satellite-dropdown/mixin'], function (exports, _item, _mixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_mixin.default, {
    layout: _item.default,

    tagName: 'button',
    attributeBindings: ['href', 'download', 'title']
  });
});