define('shuttle/models/shuttle-relation', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    type: (0, _attr.default)('string'),

    // Relations
    person: (0, _relationships.belongsTo)('shuttle-person', { inverse: 'relations' }),
    relatedTo: (0, _relationships.belongsTo)('shuttle-person', { inverse: 'beingRelatedTo' })
  });
});