define('auth/services/auth-ajax', ['exports', 'ember-ajax/services/ajax'], function (exports, _ajax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ajax.default.extend({
    session: Ember.inject.service(),

    trustedHosts: [/\.worldjewishcongress\.org/],

    headers: Ember.computed(function () {
      return this.get('session').requestHeaders();
    }).volatile()
  });
});