define('shuttle/components/shuttle-person/new', ['exports', 'shuttle/templates/components/shuttle-person/new'], function (exports, _new) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _new.default,
    store: Ember.inject.service('store'),
    notify: Ember.inject.service('satellite-notify'),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('person', Ember.Object.create({ tags: Ember.A([]) }));
    },


    actions: {
      save: function save(attrs) {
        var onCreate = this.get('oncreate');
        var notify = this.get('notify');
        var person = this.get('store').createRecord('shuttle-person');

        person.setProperties(attrs);

        return person.save().then(function (person) {
          notify.success('Person was created successfully.');
          onCreate(person.id);
        });
      }
    }
  });
});