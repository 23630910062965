define('satellite/components/satellite-progress', ['exports', 'satellite/templates/components/satellite-progress'], function (exports, _satelliteProgress) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _satelliteProgress.default,
    classNames: ['satellite-progress']
  });
});