define('shuttle/components/shuttle-widget', ['exports', 'shuttle/templates/components/shuttle-widget'], function (exports, _shuttleWidget) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _shuttleWidget.default,
    classNames: ['shuttle-widget', 'flex-row'],
    widget: Ember.inject.service('shuttle-widget'),
    state: Ember.computed.readOnly('widget.currentState'),

    actions: {
      open: function open() {
        var _get;

        (_get = this.get('widget')).open.apply(_get, arguments);
      },
      alter: function alter(options) {
        this.get('widget').alter(options);
      },
      openTemp: function openTemp() {
        var _get2;

        (_get2 = this.get('widget')).temp.apply(_get2, arguments);
      },
      openPerson: function openPerson(id) {
        this.get('widget').open('person', { personId: id });
      },
      openInstitution: function openInstitution(id) {
        this.get('widget').open('institution', { institutionId: id });
      },
      back: function back() {
        this.get('widget').back();
      }
    }
  });
});