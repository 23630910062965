define('satellite/components/satellite-promise', ['exports', 'satellite/templates/components/satellite-promise'], function (exports, _satellitePromise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SatellitePromise = Ember.Component.extend({
    layout: _satellitePromise.default,

    tagName: '',

    isLoading: true,
    result: null,

    promise: Ember.computed({
      set: function set(key, promise) {
        var _this = this;

        if (!promise || typeof promise.then !== 'function') {
          this._setIsLoading(false, promise);
          return promise;
        }

        this._setIsLoading(true, null);

        promise.then(function (result) {
          _this._setIsLoading(false, result);
        }).catch(function () {
          _this._setIsLoading(false, null);
        });

        return promise;
      }
    }),

    _setIsLoading: function _setIsLoading(value, result) {
      if (this.get('isDestroying') || this.get('isDestroyed')) {
        return;
      }

      this.setProperties({
        isLoading: value,
        result: result
      });
    }
  });

  SatellitePromise.reopenClass({
    positionalParams: ['promise']
  });

  exports.default = SatellitePromise;
});