define('repo/components/repo-list/file', ['exports', 'repo/templates/components/repo-list/file'], function (exports, _file) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _file.default,

    classNames: ['repo-file', 'repo-list-item', 'flex-row', 'flex-center', 'animate'],

    file: Ember.computed.reads('record')
  });
});