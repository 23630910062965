define('satellite/components/satellite-nav', ['exports', 'satellite/constants/apps', 'satellite/templates/components/satellite-nav'], function (exports, _apps, _satelliteNav) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _satelliteNav.default,

    router: Ember.inject.service(),

    tagName: 'nav',
    classNames: ['satellite-nav', 'satellite-nav-fixed', 'flex-row'],
    logout: 'logout',
    _testingAvatar: Ember.testing,

    appName: 'Satellite',
    isDropdownVisible: false,

    currentURL: Ember.computed.readOnly('router.currentURL'),
    currentRouteName: Ember.computed.readOnly('router.currentRouteName'),

    avatar: Ember.computed('user.id', function () {
      var testAvatar = '/satellite/tests/pixel.png';
      var avatar = 'https://auth.worldjewishcongress.org/avatar/' + this.get('user.id') + '?white';

      return this._testingAvatar ? testAvatar : avatar;
    }),

    userApps: Ember.computed('user.apps', 'currentCountryISO', function () {
      var _this = this;

      var userApps = this.get('user.apps');
      var currentCountryISO = this.get('currentCountryISO');

      return _apps.default.reduce(function (acc, app) {
        if (userApps && userApps[app.key] && userApps[app.key] !== 'none') {
          var link = _this._appendRedirectToLink(app, currentCountryISO);
          app = Ember.assign({}, app, { link: link });
          acc.push(app);
        }

        return acc;
      }, []);
    }),

    crumbs: Ember.computed('currentURL', 'currentRouteName', function () {
      var crumbs = this._mapRoutes(function (_ref) {
        var path = _ref.path,
            route = _ref.route;

        var title = route ? Ember.get(route, 'title') : '';
        return title ? { path: path, title: title } : null;
      }).filter(Ember.isPresent);

      this._setPageTitle(crumbs);

      return crumbs;
    }),

    currentCountryISO: Ember.computed('currentURL', 'currentRouteName', function () {
      return this._mapRoutes(function (_ref2) {
        var route = _ref2.route;
        return Ember.get(route, 'countryISO');
      }).filter(Ember.isPresent)[0];
    }),

    _mapRoutes: function _mapRoutes(callback) {
      var container = Ember.getOwner(this);
      var routeNames = (this.get('currentRouteName') || '').split('.');

      return routeNames.filter(Ember.isPresent).map(function (name, index) {
        var path = routeNames.slice(0, index + 1).join('.');
        var route = container.lookup('route:' + path);

        return callback({ path: path, route: route });
      });
    },
    _setPageTitle: function _setPageTitle(crumbs) {
      if (document) {
        var title = this.get('appName');

        if (Ember.isPresent(crumbs)) {
          var front = crumbs.map(function (crumb) {
            return crumb.title;
          }).reverse().join(' - ');
          title = front + ' :: ' + title;
        }

        document.title = title;
      }
    },
    _appendRedirectToLink: function _appendRedirectToLink(app, currentCountryISO) {
      if (Ember.isEmpty(currentCountryISO)) {
        return app.link;
      }

      switch (app.key) {
        case 'security':
          return app.link + '/open/' + currentCountryISO;

        case 'desks':
          return app.link + '/countries/open/' + currentCountryISO;

        default:
          return app.link;
      }
    },


    actions: {
      logout: function logout() {
        this.sendAction('logout'); // eslint-disable-line ember/closure-actions
      }
    }
  });
});