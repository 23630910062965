define('repo/models/repo-file', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    // Attributes
    app: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    file: (0, _attr.default)('string'),
    url: (0, _attr.default)('string', { readOnly: true }),
    extension: (0, _attr.default)('string'),
    createdAt: (0, _attr.default)('date', { readOnly: true }),
    createdBy: (0, _attr.default)('', { readOnly: true }),

    // Relationships
    tags: (0, _relationships.hasMany)('repoTag')
  });
});