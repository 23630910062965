define('repo/components/repo-list/tag', ['exports', 'repo/templates/components/repo-list/tag'], function (exports, _tag) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _tag.default,

    classNames: ['repo-list-tag', 'repo-list-item', 'flex-row', 'flex-center', 'animate'],

    tag: Ember.computed.reads('record')
  });
});