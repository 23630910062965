define('satellite/components/satellite-submit', ['exports', 'satellite/templates/components/satellite-submit'], function (exports, _satelliteSubmit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _satelliteSubmit.default,

    classNameBindings: [':satellite-btn', ':satellite-submit', '_isLoading:-is-loading'],
    attributeBindings: ['type', 'resolveDisabled:disabled'],
    tagName: 'button',
    type: 'button',

    _isLoading: Ember.computed.or('isSaving', 'isLoading'),
    resolveDisabled: Ember.computed.or('_isLoading', 'disabled')
  });
});