define('shuttle/components/shuttle-institution/basic-info-form', ['exports', 'shuttle/templates/components/shuttle-institution/basic-info-form'], function (exports, _basicInfoForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _basicInfoForm.default,

    dialog: Ember.inject.service('satellite-dialog'),

    isSaving: false,

    init: function init() {
      this._super.apply(this, arguments);

      this.set('model', Ember.Object.create({
        name: this.get('institution.name'),
        officialName: this.get('institution.officialName'),
        abbreviation: this.get('institution.abbreviation'),
        pointPerson: this.get('institution.pointPerson'),
        tags: this.get('institution.tags').toArray()
      }));
    },


    actions: {
      save: function save() {
        this.set('isSaving', true);
        this.get('onsave')(this.get('model'));
      },
      archive: function archive() {
        var _this = this;

        var onarchive = this.get('onarchive');

        this.get('dialog').confirm({
          title: 'Are you sure?',
          message: 'Once archived you won\'t see the record anymore',
          confirmLabel: 'Archive',
          cancelLabel: 'Cancel'
        }).then(function () {
          _this.set('isSaving', true);
          onarchive();
        });
      }
    }
  });
});