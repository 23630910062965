define('shuttle/models/shuttle-contact', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    // Attributes
    isPrimary: (0, _attr.default)('boolean'),
    label: (0, _attr.default)('string'),
    value: (0, _attr.default)('string'),

    // Relations
    person: (0, _relationships.belongsTo)('shuttle-person'),
    institution: (0, _relationships.belongsTo)('shuttle-institution'),
    membership: (0, _relationships.belongsTo)('shuttle-membership'),
    headquarter: (0, _relationships.belongsTo)('shuttle-headquarter')
  });
});