define('shuttle/components/shuttle-slot/header', ['exports', 'shuttle/templates/components/shuttle-slot/header'], function (exports, _header) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _header.default,

    classNameBindings: [':shuttle-slot-header', ':flex-row', ':flex-center', ':animate', 'isActive'],

    click: function click() {
      this.get('onclick')(!this.get('isActive'));
    }
  });
});