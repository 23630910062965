define('shuttle/components/shuttle-select/selected', ['exports', 'shuttle/templates/components/shuttle-select/selected'], function (exports, _selected) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _selected.default,
    tagName: 'span'
  });
});