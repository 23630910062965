define('repo/helpers/repo-full-access', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    session: Ember.inject.service(),

    onSessionUpdate: Ember.observer('session.user.apps.users', function () {
      this.recompute();
    }),

    compute: function compute() {
      return this.get('session.user.apps.users') === 'full';
    }
  });
});