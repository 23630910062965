define('shuttle/components/shuttle-person/relation', ['exports', 'shuttle/templates/components/shuttle-person/relation'], function (exports, _relation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _relation.default,
    classNames: ['shuttle-preview-list-item', 'flex-row', 'flex-center'],

    // Actions
    ondelete: Ember.RSVP.resolve
  });
});