define('satellite/components/satellite-empty-message', ['exports', 'satellite/templates/components/satellite-empty-message'], function (exports, _satelliteEmptyMessage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _satelliteEmptyMessage.default,

    classNames: ['satellite-empty-message'],

    label: 'records',
    filters: Ember.computed(function () {
      return {};
    }),

    // Actions
    onclear: Ember.RSVP.resolve,

    hasQuery: Ember.computed('filters', function () {
      var filters = this.get('filters');

      return Object.keys(filters).filter(function (key) {
        return !Ember.isEmpty(filters[key]);
      }).length > 0;
    })
  });
});