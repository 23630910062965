define('satellite/components/satellite-scrollbar', ['exports', 'perfect-scrollbar', 'satellite/utils/is-touch-device', 'satellite/templates/components/satellite-scrollbar'], function (exports, _perfectScrollbar, _isTouchDevice, _satelliteScrollbar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _satelliteScrollbar.default,

    classNameBindings: [':satellite-scrollbar-wrapper', 'modifierClass', '_onStart:start', '_onEnd:end'],

    wheelSpeed: 1,
    wheelPropagation: false,
    swipePropagation: true,
    minScrollbarLength: null,
    maxScrollbarLength: null,
    useBothWheelAxes: false,
    useKeyboard: true,
    suppressScrollX: true,
    suppressScrollY: false,
    scrollTop: 0,
    scrollLeft: 0,
    scrollXMarginOffset: 0,
    scrollYMarginOffset: 0,
    includePadding: false,

    _onStart: true,
    _onEnd: true,

    init: function init() {
      this._super.apply(this, arguments);

      this.set('modifierClass', _isTouchDevice.default ? '-native' : '-custom');
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._node = this.$('> div');
      Ember.run.scheduleOnce('afterRender', this, this._initScrollbar);
    },
    willDestroyElement: function willDestroyElement() {
      this._destroyScrollbar();
      this._super.apply(this, arguments);
    },
    _initScrollbar: function _initScrollbar() {
      var _this = this;

      Ember.run(function () {
        _this._attachUpdateEvents();

        if (_isTouchDevice.default) {
          return;
        }

        _this._perfectScrollbar = new _perfectScrollbar.default(_this._node[0], _this._getOptions());
        _this._setInitialOverlay();
        _this._attachOverlayEvents();
      });
    },
    _destroyScrollbar: function _destroyScrollbar() {
      var _this2 = this;

      Ember.run(function () {
        _this2._detachUpdateEvents();

        if (_isTouchDevice.default) {
          return;
        }

        _this2._perfectScrollbar.destroy();
        _this2._detachOverlayEvents();
      });
    },
    _updateScrollbar: function _updateScrollbar(position) {
      var _this3 = this;

      if (position === 'bottom') {
        this._node[0].scrollTop = this._node[0].scrollHeight + 10;
      } else if (!Ember.isEmpty(position)) {
        this._node[0].scrollTop = position;
      }

      if (_isTouchDevice.default) {
        return;
      }

      Ember.run(function () {
        _this3._perfectScrollbar.update();
        _this3._setInitialOverlay();
      });

      if (position === 'bottom') {
        this.set('_onEnd', true);
      }
    },
    _setInitialOverlay: function _setInitialOverlay() {
      var scrollHeight = this._node[0].scrollHeight;
      var height = this._node.outerHeight();

      if (scrollHeight <= height) {
        this.setProperties({
          _onStart: true,
          _onEnd: true
        });
      } else if (scrollHeight > height) {
        this.set('_onEnd', false);
      }
    },
    _getOptions: function _getOptions() {
      return this.getProperties('wheelSpeed', 'wheelPropagation', 'swipePropagation', 'minScrollbarLength', 'maxScrollbarLength', 'useBothWheelAxes', 'useKeyboard', 'suppressScrollX', 'suppressScrollY', 'scrollXMarginOffset', 'scrollYMarginOffset', 'includePadding');
    },
    _attachUpdateEvents: function _attachUpdateEvents() {
      var _this4 = this;

      this.$().on('update-scroll', function () {
        return Ember.run.scheduleOnce('afterRender', _this4, _this4._updateScrollbar);
      }).on('reset-scroll', function () {
        return Ember.run.scheduleOnce('afterRender', _this4, _this4._updateScrollbar, 0);
      }).on('scroll-to-bottom', function () {
        return Ember.run.scheduleOnce('afterRender', _this4, _this4._updateScrollbar, 'bottom');
      });
    },
    _attachOverlayEvents: function _attachOverlayEvents() {
      var _this5 = this;

      this._node.on('ps-scroll-down', function () {
        if (_this5.get('_onStart')) {
          _this5.set('_onStart', false);
        }
      }).on('ps-scroll-up', function () {
        if (_this5.get('_onEnd')) {
          _this5.set('_onEnd', false);
        }
      }).on('ps-y-reach-end', function () {
        if (!_this5.get('_onEnd')) {
          _this5.set('_onEnd', true);
        }
      }).on('ps-y-reach-start', function () {
        if (!_this5.get('_onStart')) {
          _this5.set('_onStart', true);
        }
      });
    },
    _detachUpdateEvents: function _detachUpdateEvents() {
      this.$().off('update-scroll reset-scroll scroll-to-bottom');
    },
    _detachOverlayEvents: function _detachOverlayEvents() {
      this._node.off('ps-scroll-down ps-scroll-up ps-y-reach-end ps-y-reach-start');
    }
  });
});