define('shuttle/services/shuttle-full-access', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),

    hasFullAccess: function hasFullAccess() {
      var access = false;

      if (this.get('session.isAuthenticated')) {
        return this.get('session.user.apps.contacts') == 'full';
      }

      return access;
    }
  });
});