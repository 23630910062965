define('satellite/components/satellite-error-page/404', ['exports', 'satellite/templates/components/satellite-error-page/404'], function (exports, _) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _.default,
    'data-autoid': 'error-page-404'
  });
});