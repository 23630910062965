define('satellite/components/satellite-power-select', ['exports', 'satellite/mixins/base-input', 'satellite/templates/components/satellite-power-select'], function (exports, _baseInput, _satellitePowerSelect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_baseInput.default, {
    layout: _satellitePowerSelect.default,

    selected: Ember.computed.alias('value'),
    classNames: ['satellite-power-select'],
    allowClear: true,
    searchPlaceholder: 'Filter the list...',
    searchField: 'label',

    selectedOption: Ember.computed('selected', 'collection', function () {
      var _this = this;

      return this.get('collection') && Ember.A(this.get('collection')).find(function (option) {
        return option.value === _this.get('selected');
      });
    }),

    resolveTriggerClass: Ember.computed('triggerClass', function () {
      return 'satellite-power-select-trigger ' + (this.get('triggerClass') || '');
    }),

    resolveDropdownClass: Ember.computed('dropdownClass', function () {
      return 'satellite-power-select-dropdown ' + (this.get('dropdownClass') || '');
    }),

    actions: {
      handleChange: function handleChange(option) {
        this.set('selected', option ? option.value : null);
      }
    }
  });
});