define('repo/components/repo-recent-files/item', ['exports', 'repo/templates/components/repo-recent-files/item'], function (exports, _item) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _item.default,
    classNames: ['repo-file', '-small', 'flex-row', 'flex-center']
  });
});