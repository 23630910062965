define('shuttle/components/shuttle-person/relation-form', ['exports', 'shuttle/templates/components/shuttle-person/relation-form'], function (exports, _relationForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TYPES = [{
    groupName: 'Family',
    options: ['Child', 'Grandchild', 'Grandparent', 'Parent', 'Partner', 'Relative', 'Spouse']
  }, {
    groupName: 'Professional',
    options: ['Assistant', 'Security Guard', 'Security Supervisor', 'Supervisor', 'WJC Point Person']
  }];

  exports.default = Ember.Component.extend({
    layout: _relationForm.default,

    types: TYPES,

    init: function init() {
      this._super.apply(this, arguments);

      this.setProperties({
        type: null,
        relatedTo: null
      });
    },


    actions: {
      save: function save() {
        var attrs = this.getProperties('type', 'relatedTo');
        this.set('disabled', true);
        this.get('onsave')(attrs).then(this.get('oncancel'));
      }
    }
  });
});