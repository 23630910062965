define('satellite/helpers/or', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.or = or;
  function or(params) {
    var result = false;

    params.forEach(function (value) {
      if (value) {
        result = true;
      }
    });

    return result;
  }

  exports.default = Ember.Helper.helper(or);
});