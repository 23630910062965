define('shuttle/components/shuttle-list/person', ['exports', 'shuttle/templates/components/shuttle-list/person'], function (exports, _person) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _person.default,
    classNames: ['shuttle-list-item', 'animate']
  });
});