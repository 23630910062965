define('satellite/utils/humanize', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = humanize;
  function humanize(value) {
    if (value) {
      var result = Ember.String.underscore(value + '').replace(/_/g, ' ');
      return result.charAt(0).toUpperCase() + result.slice(1);
    }
  }
});