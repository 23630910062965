define('shuttle/components/shuttle-person/basic-info-form', ['exports', 'shuttle/templates/components/shuttle-person/basic-info-form', 'shuttle/statics/titles', 'shuttle/statics/genders'], function (exports, _basicInfoForm, _titles, _genders) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _basicInfoForm.default,

    dialog: Ember.inject.service('satellite-dialog'),

    titles: _titles.default,
    genders: _genders.default,
    isSaving: false,

    init: function init() {
      this._super.apply(this, arguments);

      this.set('model', Ember.Object.create({
        title: this.get('person.title'),
        firstName: this.get('person.firstName'),
        lastName: this.get('person.lastName'),
        gender: this.get('person.gender'),
        pointPerson: this.get('person.pointPerson'),
        tags: this.get('person.tags').toArray()
      }));
    },


    actions: {
      save: function save() {
        this.set('isSaving', true);
        this.get('onsave')(this.get('model'));
      },
      archive: function archive() {
        var _this = this;

        var onarchive = this.get('onarchive');

        this.get('dialog').confirm({
          title: 'Are you sure?',
          message: 'Once archived you won\'t see the record anymore',
          confirmLabel: 'Archive',
          cancelLabel: 'Cancel'
        }).then(function () {
          _this.set('isSaving', true);
          onarchive();
        });
      }
    }
  });
});