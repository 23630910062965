define('satellite/components/satellite-error-page', ['exports', 'satellite/templates/components/satellite-error-page'], function (exports, _satelliteErrorPage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _satelliteErrorPage.default,
    classNames: ['satellite-error-page'],

    errorComponent: Ember.computed('error.status', function () {
      var status = (this.get('error.status') || '').toString();

      if (status === '404') {
        return 'satellite-error-page/404';
      } else if (status.indexOf('4') === 0) {
        return 'satellite-error-page/400';
      } else if (status.indexOf('5') === 0) {
        return 'satellite-error-page/500';
      } else {
        return 'satellite-error-page/generic';
      }
    })
  });
});