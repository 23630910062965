define('satellite/services/satellite-json', ['exports', 'ember-ajax/services/ajax'], function (exports, _ajax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ajax.default.extend({
    request: function request(url, options) {
      options = Ember.merge({
        type: 'GET',
        dataType: 'json',
        contentType: 'application/json; charset=UTF-8'
      }, options);

      if (options.data && options.type !== 'GET') {
        options.data = JSON.stringify(options.data);
      }

      return this._super(url, options);
    },
    get: function get(url, data) {
      return this.request(url, { data: data });
    },
    post: function post(url, data) {
      return this.request(url, { type: 'POST', data: data });
    },
    patch: function patch(url, data) {
      return this.request(url, { type: 'PATCH', data: data });
    },
    put: function put(url, data) {
      return this.request(url, { type: 'PUT', data: data });
    },
    delete: function _delete(url) {
      return this.request(url, { type: 'DELETE' });
    }
  });
});