define('repo/components/repo-country-sidebar-button', ['exports', 'repo/templates/components/repo-country-sidebar-button'], function (exports, _repoCountrySidebarButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _repoCountrySidebarButton.default,

    widget: Ember.inject.service('repo-widget'),

    actions: {
      open: function open() {
        var tags = this.get('tags').split(',');
        this.get('widget').temp('files', { tags: tags });
      }
    }
  });
});