define('auth/utils', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.loginURL = loginURL;
  function loginURL(ENV) {
    if (typeof window === 'undefined') {
      return;
    }

    return [ENV.AUTH_LOGIN_URL, '?callback=', window.location.protocol, '//', window.location.host, ENV.AUTH_CALLBACK_PATH].join('');
  }
});