define('repo/components/repo-mounter', ['exports', 'repo/templates/components/repo-mounter'], function (exports, _repoMounter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _repoMounter.default,
    widget: Ember.inject.service('repo-widget')
  });
});