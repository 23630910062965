define('satellite/components/satellite-error-page/footer', ['exports', 'satellite/templates/components/satellite-error-page/footer'], function (exports, _footer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _footer.default,
    classNames: ['satellite-error-page-footer']
  });
});