define('satellite/components/satellite-checkbox', ['exports', 'satellite/mixins/base-input', 'satellite/templates/components/satellite-checkbox'], function (exports, _baseInput, _satelliteCheckbox) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_baseInput.default, {
    layout: _satelliteCheckbox.default,

    checked: Ember.computed.alias('value'),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super();

      this.$('input[type=checkbox]').on('focusin', function () {
        _this.set('isFocused', 'is-focused');
      }).on('focusout', function () {
        _this.set('isFocused', null);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.$('input[type=checkbox]').off('focusin focusout');
    }
  });
});