define('shuttle/models/shuttle-person', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    // Attributes
    title: (0, _attr.default)('string'),
    firstName: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    gender: (0, _attr.default)('string'),
    position: (0, _attr.default)('string'),
    isArchived: (0, _attr.default)('boolean'),

    // Relations
    pointPerson: (0, _relationships.belongsTo)('shuttle-person', { inverse: null }),
    contacts: (0, _relationships.hasMany)('shuttle-contact'),
    memberships: (0, _relationships.hasMany)('shuttle-membership'),
    primaryMembership: (0, _relationships.belongsTo)('shuttle-membership'),
    tags: (0, _relationships.hasMany)('shuttle-tag'),
    relations: (0, _relationships.hasMany)('shuttle-relation', { inverse: 'person' }),
    beingRelatedTo: (0, _relationships.hasMany)('shuttle-relation', { inverse: 'relatedTo' }),

    // Computed
    selectTitle: Ember.computed.readOnly('fullName'),
    selectSubtitle: Ember.computed.readOnly('position'),

    fullName: Ember.computed('title', 'firstName', 'lastName', function () {
      return Ember.A([this.get('title'), this.get('firstName'), this.get('lastName')]).compact().join(' ');
    })
  });
});