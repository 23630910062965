define('shuttle/components/shuttle-select/create-option', ['exports', 'shuttle/templates/components/shuttle-select/create-option'], function (exports, _createOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _createOption.default,
    tagName: 'span',
    classNames: ['create-option'],

    createResourceLabel: 'record'
  });
});