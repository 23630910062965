define('auth/store', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Store = Ember.Object.extend(Ember.Evented, {
    base: 'wjc-auth',
    _data: {},

    persist: function persist(key, value) {
      this._data[key] = value;

      localStorage.setItem(this.base + ':' + key, value);
    },
    retrieve: function retrieve(key) {
      return localStorage.getItem(this.base + ':' + key);
    },
    clear: function clear() {
      var _this = this;

      this._data = {};

      Object.keys(localStorage).forEach(function (key) {
        if (key.indexOf(_this.base) === 0) {
          localStorage.removeItem(key);
        }
      });
    },


    bindStorageEvents: Ember.on('init', function () {
      var _this2 = this;

      Ember.$(window).on('storage', function () {
        var token = _this2.retrieve('token');

        if (token !== Ember.get(_this2._data, 'token')) {
          _this2._data.token = token;
          _this2.trigger('storeDataUpdated');
        }
      });
    })
  });

  var InMemoryStore = Ember.Object.extend(Ember.Evented, {
    _data: {},

    initialize: Ember.on('init', function () {
      this._data = {};
    }),

    persist: function persist(key, value) {
      this._data[key] = value;
    },
    retrieve: function retrieve(key) {
      return this._data[key];
    },
    clear: function clear() {
      this._data = {};
    }
  });

  exports.Store = Store;
  exports.InMemoryStore = InMemoryStore;
  exports.default = Store;
});