define('satellite/components/satellite-icon', ['exports', 'satellite/templates/components/satellite-icon'], function (exports, _satelliteIcon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SatelliteIcon = Ember.Component.extend({
    layout: _satelliteIcon.default,
    tagName: 'span',
    classNames: ['icon']
  });

  SatelliteIcon.reopenClass({
    positionalParams: ['icon']
  });

  exports.default = SatelliteIcon;
});