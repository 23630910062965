define('satellite/components/satellite-dropdown', ['exports', 'satellite/templates/components/satellite-dropdown'], function (exports, _satelliteDropdown) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _satelliteDropdown.default,

    tagName: '',
    icon: 'more_vert',
    renderInPlace: false,

    dropdownClass: Ember.computed('renderInPlace', 'class', function () {
      var base = ['satellite-dropdown'];

      if (this.get('renderInPlace')) {
        base.push(this.get('class'));
      }

      return base.join(' ');
    }),

    triggerClass: Ember.computed('renderInPlace', 'class', function () {
      var base = ['satellite-dropdown-trigger', 'satellite-circle-btn', 'satellite-circle-btn-blank'];

      if (!this.get('renderInPlace')) {
        base.push(this.get('class'));
      }

      return base.join(' ');
    })
  });
});