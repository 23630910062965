define('auth/routes/auth-callback', ['exports', 'auth/mixins/unauthenticated-route', 'auth/base64'], function (exports, _unauthenticatedRoute, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRoute.default, {
    session: Ember.inject.service('session'),

    _skipAuthentication: true,

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.get('session').authenticate(controller.get('token'));

      if (this.get('session.isAuthenticated') && controller.get('to')) {
        var to = _base.default.decode(controller.get('to'));
        this.transitionTo(to);
      }
    }
  });
});