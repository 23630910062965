define('repo/components/repo-button', ['exports', 'repo/templates/components/repo-button'], function (exports, _repoButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _repoButton.default,

    store: Ember.inject.service(),
    widget: Ember.inject.service('repo-widget'),

    tagName: 'button',
    attributeBindings: ['type'],
    classNames: ['repo-button', 'animate', 'reset-button'],
    type: 'button',

    tags: [],
    scopedToCurrentRoute: false,

    count: Ember.computed('countFor', function () {
      return this.get('store').query('repo-file', {
        filter: this.get('countFor'),
        page: { number: 10000, size: 1 // Dirty trick
        } }).then(function (_ref) {
        var meta = _ref.meta;
        return meta.total;
      });
    }),

    click: function click() {
      this.get('widget').openScopedToCurrentRoute();
    }
  });
});