define('shuttle/components/shuttle-people-loader', ['exports', 'shuttle/templates/components/shuttle-people-loader'], function (exports, _shuttlePeopleLoader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ShuttlePeopleLoader = Ember.Component.extend({
    layout: _shuttlePeopleLoader.default,
    store: Ember.inject.service(),

    peoplePromise: Ember.computed('query', function () {
      return this.get('store').query('shuttle-person', this.get('query') || {});
    })
  });

  ShuttlePeopleLoader.reopenClass({
    positionalParams: ['query']
  });

  exports.default = ShuttlePeopleLoader;
});