define('shuttle/components/shuttle-type-svg', ['exports', 'shuttle/templates/components/shuttle-type-svg'], function (exports, _shuttleTypeSvg) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PATHS = {
    'institutions-list': 'M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h5v-2H7v2zm0 4h5v-2H7v2zM7 7v2h14V7H7zM17 11.556V10h-4v7h8v-5.444h-4zm-2.4 4.666h-.8v-.778h.8v.778zm0-1.555h-.8v-.778h.8v.777zm0-1.556h-.8v-.777h.8v.778zm0-1.554h-.8v-.778h.8v.778zm1.6 4.666h-.8v-.778h.8v.778zm0-1.555h-.8v-.778h.8v.777zm0-1.556h-.8v-.777h.8v.778zm0-1.554h-.8v-.778h.8v.778zm4 4.666H17v-.778h.8v-.777H17v-.778h.8v-.78H17v-.777h3.2v3.89zm-.8-3.11h-.8v.777h.8v-.78zm0 1.555h-.8v.777h.8v-.777z',
    'people-list': 'M3 13h2v-2H3v2zm0 4h2v-2H3v2zm12.822-4h-.645.645zm3.357 0H21v-2h-1.306c.036.16.055.328.055.5 0 .577-.216 1.102-.57 1.5zm-7.876-2H7v2h4.82c-.355-.398-.57-.923-.57-1.5 0-.172.018-.34.054-.5zM3 9V7h2v2H3zm6.335 6H7v2h2v-1.18c0-.303.124-.577.335-.82zM7 7v2h14V7H7zm10.5 6c.83 0 1.495-.67 1.495-1.5S18.33 10 17.5 10c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5zm-4 0c.83 0 1.495-.67 1.495-1.5S14.33 10 13.5 10c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5zm0 1c-1.165 0-3.5.585-3.5 1.75V17h7v-1.25c0-1.165-2.335-1.75-3.5-1.75zm4 0c-.145 0-.31.01-.485.025.58.42.985.985.985 1.725V17h3v-1.25c0-1.165-2.335-1.75-3.5-1.75z',
    institution: 'M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z',
    list: 'M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7z',
    person: 'M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z'
  };

  PATHS['contact-list'] = PATHS['people-list'];
  PATHS['institution-list'] = PATHS['institutions-list'];

  var TypeSVG = Ember.Component.extend({
    layout: _shuttleTypeSvg.default,
    attributeBindings: ['width', 'height', 'viewBox', 'xmlns'],
    classNames: ['shuttle-type-svg'],
    tagName: 'svg',

    height: '24',
    width: '24',
    viewBox: '0 0 24 24',
    xmlns: 'http://www.w3.org/2000/svg',

    path: Ember.computed('svg', function () {
      return PATHS[this.get('svg')];
    })
  });

  TypeSVG.reopenClass({
    positionalParams: ['svg']
  });

  exports.default = TypeSVG;
});