define('satellite/components/satellite-widgets', ['exports', 'satellite/utils/is-touch-device', 'satellite/templates/components/satellite-widgets'], function (exports, _isTouchDevice, _satelliteWidgets) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _satelliteWidgets.default,

    didInsertElement: function didInsertElement() {
      Ember.$('#app-loader').remove();

      if (_isTouchDevice.default) {
        Ember.$('body').addClass('touch-device');
      }
    }
  });
});