define('shuttle/components/shuttle-list/institution', ['exports', 'shuttle/templates/components/shuttle-list/institution'], function (exports, _institution) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _institution.default,
    classNames: ['shuttle-list-item', 'animate'],

    info: Ember.computed('institution.{abbreviation,officialName}', function () {
      return Ember.A([this.get('institution.abbreviation'), this.get('institution.officialName')]).compact().join(' / ');
    })
  });
});