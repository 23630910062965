define('shuttle/components/shuttle-preview/field', ['exports', 'shuttle/templates/components/shuttle-preview/field'], function (exports, _field) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _field.default,
    classNameBindings: [':shuttle-preview-field'],

    isEmpty: Ember.computed.empty('value')
  });
});