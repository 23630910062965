define('satellite/components/satellite-radio-buttons/item', ['exports', 'satellite/templates/components/satellite-radio-buttons/item'], function (exports, _item) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _item.default,

    tagName: 'label',
    classNameBindings: [':satellite-field-label-radio', 'disabled'],

    isChecked: Ember.computed('checked', 'value', function () {
      return this.get('checked') === this.get('value');
    }),

    actions: {
      change: function change() {
        this.get('on-change')(this.get('value'));
      }
    }
  });
});