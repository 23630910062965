define('auth/mixins/application-route', ['exports', 'auth/base64'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    session: Ember.inject.service('session'),

    activate: function activate() {
      this.set('_authRouteEntryComplete', true);
      this._super.apply(this, arguments);
    },
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      this.get('session').restore();

      if (!this.get('_authEventListenersAssigned')) {
        this.set('_authEventListenersAssigned', true);

        var object = this.get('_authRouteEntryComplete') ? this : transition;

        this.get('session').on('sessionAuthenticated', function () {
          return object.send('sessionAuthenticated');
        });
        this.get('session').on('sessionInvalidated', function () {
          return object.send('sessionInvalidated');
        });
      }
    },
    afterLoginPath: function afterLoginPath() {
      return window.location.pathname + window.location.search;
    },


    actions: {
      login: function login() {
        if (Ember.testing) {
          return;
        }

        var url = this.get('session.loginURL') + '?to=' + _base.default.encode(this.afterLoginPath());
        window.location.replace(url);
      },
      logout: function logout() {
        this.get('session').invalidate();

        if (!Ember.testing) {
          window.location.replace(this.get('session.logoutURL'));
        }
      },
      sessionAuthenticated: function sessionAuthenticated() {
        // Triggered whenever the session is authenticated
      },
      sessionInvalidated: function sessionInvalidated() {
        // Do whatever you want
        // Common use case will be to use satellite-dialog to prompt
        // user to login if he likes to use the app
      }
    }
  });
});