define('satellite/components/satellite-notify', ['exports', 'satellite/templates/components/satellite-notify'], function (exports, _satelliteNotify) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    notify: Ember.inject.service('satellite-notify'),

    layout: _satelliteNotify.default,
    classNameBindings: [':satellite-notify'],

    messages: Ember.computed.reads('notify.messages'),

    actions: {
      removeMessage: function removeMessage(message) {
        this.get('messages').removeObject(message);
      }
    }

  });
});