define('shuttle/mixins/jb2-adapter', ['exports', 'auth/mixins/adapter'], function (exports, _adapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_adapter.default, {
    host: 'https://gum.worldjewishcongress.org',
    namespace: 'api/jb2'
  });
});