define('auth/mixins/authenticated-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    session: Ember.inject.service('session'),

    _skipAuthentication: false,

    beforeModel: function beforeModel(transition) {
      if (!this._skipAuthentication && !this.get('session.isAuthenticated')) {
        transition.abort();
        transition.send('login');
      } else {
        return this._super(transition);
      }
    }
  });
});