define('shuttle/components/shuttle-preview/accordion', ['exports', 'shuttle/templates/components/shuttle-preview/accordion'], function (exports, _accordion) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Accordion = Ember.Component.extend({
    layout: _accordion.default,

    classNameBindings: [':shuttle-preview-accordions', 'hasOpenedItem'],
    key: 'id',

    hasOpenedItem: Ember.computed.notEmpty('currentIndex'),

    currentIndex: Ember.computed('disabled', {
      get: function get() {
        if (this.get('disabled')) {
          this._currentIndex = null;
        }

        return this._currentIndex;
      },
      set: function set(key, value) {
        if (this.get('disabled')) {
          value = null;
        }

        this._currentIndex = value;
        return this._currentIndex;
      }
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('currentIndex', this.get('defaultCurrentIndex'));
    },


    actions: {
      expandSection: function expandSection(index) {
        index = this.get('currentIndex') === index ? null : index;
        this.set('currentIndex', index);

        if (this.get('onchange')) {
          this.get('onchange')(index);
        }
      },
      handleOnLinkClick: function handleOnLinkClick(item) {
        return this.get('onlinkclick')(item);
      }
    }
  });

  Accordion.reopenClass({
    positionalParams: ['collection']
  });

  exports.default = Accordion;
});