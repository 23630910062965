define('shuttle/components/shuttle-preview/accordion-section', ['exports', 'shuttle/templates/components/shuttle-preview/accordion-section'], function (exports, _accordionSection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _accordionSection.default,

    classNames: ['shuttle-preview-accordion', 'animate'],

    isOpened: Ember.computed('currentIndex', 'index', function () {
      return this.get('currentIndex') === this.get('index');
    }),

    headerClass: Ember.computed('currentIndex', 'isOpened', function () {
      if (this.get('isOpened')) {
        return 'is-opened';
      } else if (!Ember.isEmpty(this.get('currentIndex'))) {
        return 'is-faded';
      }
    }),

    icon: Ember.computed('isOpened', function () {
      return this.get('isOpened') ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
    })
  });
});