define('shuttle/components/shuttle-list/empty', ['exports', 'shuttle/templates/components/shuttle-list/empty'], function (exports, _empty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _empty.default,
    classNames: ['shuttle-list-empty'],

    hasQuery: Ember.computed('tags.[]', 'term', function () {
      return !Ember.isEmpty(this.get('tags')) || !Ember.isEmpty(this.get('term'));
    }),

    currentTags: Ember.computed('tags.[]', function () {
      return this.get('tags').join(', ');
    })
  });
});