define('satellite/components/satellite-empty-state', ['exports', 'satellite/templates/components/satellite-empty-state'], function (exports, _satelliteEmptyState) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.DEFAULT_ICONS = undefined;
  var DEFAULT_ICONS = exports.DEFAULT_ICONS = Object.freeze(['clear_all', 'face', 'landscape', 'map', 'panorama_horizontal', 'sentiment_satisfied', 'weekend']);

  exports.default = Ember.Component.extend({
    layout: _satelliteEmptyState.default,

    classNames: ['satellite-empty-state'],

    title: 'Looks like this page is still empty',

    init: function init() {
      this._super.apply(this, arguments);
      this._setDefaultIcon();
    },
    _setDefaultIcon: function _setDefaultIcon() {
      if (!this.icon) {
        var randomIndex = Math.floor(Math.random() * DEFAULT_ICONS.length);
        this.icon = DEFAULT_ICONS[randomIndex];
      }
    }
  });
});