define('shuttle/models/shuttle-headquarter', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    // Attributes
    address1: (0, _attr.default)('string'),
    address2: (0, _attr.default)('string'),
    city: (0, _attr.default)('string'),
    state: (0, _attr.default)('string'),
    postalCode: (0, _attr.default)('string'),
    country: (0, _attr.default)('string'),
    isPrimary: (0, _attr.default)('boolean'),

    // Relations
    contacts: (0, _relationships.hasMany)('shuttle-contact'),
    institution: (0, _relationships.belongsTo)('shuttle-institution', { inverse: 'headquarters' }),

    // Computed
    location: Ember.computed('city', 'country', function () {
      return Ember.A([this.get('city'), this.get('country')]).reject(function (item) {
        return Ember.isBlank(item);
      }).join(', ');
    })
  });
});