define('satellite/utils/test-root-element-id', ['exports', 'require'], function (exports, _require) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = testRootElementId;
  function testRootElementId(config) {
    var id = void 0;

    if (config.environment === 'test' && false && typeof FastBoot === 'undefined') {
      if (_require.default.has('@ember/test-helpers/dom/get-root-element')) {
        try {
          id = (0, _require.default)('@ember/test-helpers/dom/get-root-element').default().id;
        } catch (e) {}
      }

      if (!id) {
        var rootView = document.querySelector('#ember-testing > .ember-view');
        id = rootView && rootView.id;
      }
    }

    return id;
  }
});