define('satellite/components/satellite-redactor', ['exports', 'satellite/mixins/base-input', 'satellite/templates/components/satellite-redactor'], function (exports, _baseInput, _satelliteRedactor) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_baseInput.default, {
    layout: _satelliteRedactor.default,

    isActive: true,
    classNameBindings: [':satellite-field-redactor', 'isFocused', 'disabled'],

    toolbarFixed: true,
    toolbarTarget: document,
    toolbarOffset: 0,
    autofocus: false,
    minHeight: 300,
    maxHeight: null,
    linkSize: 100,

    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super();

      var _this = this;

      var options = {
        toolbarFixed: this.get('toolbarFixed'),
        toolbarFixedTarget: this.get('toolbarTarget'),
        toolbarFixedTopOffset: this.get('toolbarOffset'),

        focus: Ember.testing ? false : this.get('autofocus'),
        minHeight: this.get('minHeight') + 'px',
        maxHeight: this.get('maxHeight') + 'px',

        linkSize: this.get('linkSize'),

        buttons: ['bold', 'italic', 'underline', 'deleted', 'ul', 'ol', 'outdent', 'indent', 'line', 'link'],

        callbacks: {
          changed: function changed(html) {
            _this._saveSet('value', html);
          },
          focus: function focus() {
            _this._saveSet('isFocused', true);
          },
          blur: function blur() {
            _this._saveSet('isFocused', false);
          }
        }
      };

      Ember.run(function () {
        _this2._textarea = _this2.element.querySelector('textarea');
        Redactor(_this2._textarea, options);
      });
    },
    willDestroyElement: function willDestroyElement() {
      var _this3 = this;

      Ember.run(function () {
        return Redactor(_this3._textarea, 'destroy');
      });
      this._super();
    },
    _saveSet: function _saveSet(key, value) {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      this.set(key, value);
    }
  });
});