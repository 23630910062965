define('satellite/components/satellite-fieldset', ['exports', 'satellite/templates/components/satellite-fieldset'], function (exports, _satelliteFieldset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _satelliteFieldset.default,

    tagName: 'fieldset',
    classNames: ['satellite-fieldset']
  });
});