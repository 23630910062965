define('satellite/components/satellite-select', ['exports', 'satellite/mixins/base-input', 'satellite/templates/components/satellite-select'], function (exports, _baseInput, _satelliteSelect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_baseInput.default, {
    layout: _satelliteSelect.default,

    allowBlank: false,
    selected: Ember.computed.alias('value'),

    actions: {
      change: function change() {
        var newValue = null;
        var select = this.$('select')[0];

        if (this.get('allowBlank') && select.selectedIndex > 0) {
          newValue = Ember.get(this.get('collection')[select.selectedIndex - 1], 'value');
        } else if (!this.get('allowBlank')) {
          newValue = Ember.get(this.get('collection')[select.selectedIndex], 'value');
        }

        this.set('selected', newValue);
      }
    }
  });
});