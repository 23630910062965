define('satellite/components/satellite-form', ['exports', 'satellite/templates/components/satellite-form'], function (exports, _satelliteForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SatelliteForm = Ember.Component.extend({
    layout: _satelliteForm.default,

    tagName: 'form',
    autofocus: true,
    disabled: false,

    submit: function submit(e) {
      e.preventDefault();
      this.get('onsubmit')();
    },
    didInsertElement: function didInsertElement() {
      this._super();

      if (this.get('autofocus')) {
        this.$('input, textarea, select').eq(0).focus();
      }
    }
  });

  SatelliteForm.reopenClass({
    positionalParams: ['model']
  });

  exports.default = SatelliteForm;
});