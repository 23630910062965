define('satellite/services/satellite-notify', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var NotifyMessage = exports.NotifyMessage = Ember.Object.extend({
    text: null,
    type: 'info',
    closeAfter: 2500,
    isSticky: Ember.testing
  });

  exports.default = Ember.Service.extend({
    messages: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('messages', Ember.A());
    },
    error: function error(text) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.show(text, 'error', options);
    },
    info: function info(text) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.show(text, 'info', options);
    },
    success: function success(text) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.show(text, 'success', options);
    },
    show: function show(text) {
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'info';
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      var message = NotifyMessage.create(Ember.assign({
        text: text,
        type: type
      }, options));

      this.get('messages').pushObject(message);

      return message;
    }
  });
});