define('shuttle/components/shuttle-institution/new', ['exports', 'shuttle/templates/components/shuttle-institution/new'], function (exports, _new) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _new.default,
    store: Ember.inject.service('store'),
    notify: Ember.inject.service('satellite-notify'),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('institution', Ember.Object.create({ tags: Ember.A([]) }));
    },


    actions: {
      save: function save(attrs) {
        var onCreate = this.get('oncreate');
        var notify = this.get('notify');
        var institution = this.get('store').createRecord('shuttle-institution');

        institution.setProperties(attrs);

        return institution.save().then(function (institution) {
          notify.success('Institution was created successfully.');
          onCreate(institution.id);
        });
      }
    }
  });
});