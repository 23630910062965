define('shuttle/helpers/shuttle-full-access', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    authorize: Ember.inject.service('shuttle-full-access'),

    compute: function compute() {
      return this.get('authorize').hasFullAccess();
    }
  });
});