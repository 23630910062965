define('satellite/helpers/is-empty', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isEmpty = isEmpty;
  function isEmpty(params) {
    var length = params.length;

    for (var i = 0; i < length; i++) {
      if (Ember.isEmpty(params[i])) {
        return true;
      }
    }

    return false;
  }

  exports.default = Ember.Helper.helper(isEmpty);
});