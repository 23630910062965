define('satellite/components/satellite-input', ['exports', 'satellite/mixins/base-input', 'satellite/templates/components/satellite-input'], function (exports, _baseInput, _satelliteInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_baseInput.default, {
    layout: _satelliteInput.default,

    type: 'text' // Default type
  });
});