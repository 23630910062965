define('auth/mixins/unauthenticated-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    session: Ember.inject.service('session'),

    beforeModel: function beforeModel(transition) {
      if (this.get('session.isAuthenticated')) {
        transition.abort();
        this.transitionTo('application');
      }

      return this._super(transition);
    }
  });
});